<template>
  <div class="product" id="product">
    <card :arr="arr2"></card>
    <card :arr="arr1"></card>
    <card :arr="arr3"></card>
  </div>
</template>

<script>
import card from "./card/card.vue";
export default {
  name: "product",
  components: {
    card,
  },
  data() {
    return {
      arr1: {
        name: "除和",
        des: "实时数仓OLAP引擎",
        back: "https://static.xyz10.com/static/2022homePage/mobile/%E9%99%A4%E5%92%8C.png",
        icon: "https://static.xyz10.com/static/2022homePage/%E9%99%A4%E5%92%8Clogo.png",
        url: "https://chuhe.xyz10.com/",
      },
      arr2: {
        name: "当午",
        des: "全域时空智能服务",
        back: "https://static.xyz10.com/static/2022homePage/mobile/%E5%BD%93%E5%8D%88.png",
        icon: "https://static.xyz10.com/static/2022homePage/2%282%29%E7%9A%84%E5%89%AF%E6%9C%AC2.png",
        url: "https://www.dangwu.com/",
      },
      arr3: {
        name: "正午",
        des: "云上智慧政务大厅",
        back: "https://static.xyz10.com/static/2022homePage/mobile/%E6%AD%A3%E5%8D%88.png",
        icon: "https://static.xyz10.com/static/2022homePage/%E6%AD%A3%E5%8D%88logo.png",
        url: "",
        routes: "/mobileZhengwu",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.product {
  width: 100vw;
  height: 100vh;
  padding-top: 4vh;
}
</style>
