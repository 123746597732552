<template>
  <div class="banner" @mouseenter="play">
    <!-- <div class="title">XYZ<sup>10</sup></div> -->
    <div class="title">
      <img
        src="https://static.xyz10.com/static%2F2022homePage%2F%E5%90%8D%E5%AD%97-06.png"
        alt=""
      />
    </div>
    <transition name="fade">
      <div class="background" :key="currentIndex">
        <span>{{ text }}</span>
        <span>智</span>
        <img
          style="width: 30px; height: 150px; transform: translateY(-80px)"
          src="https://static.xyz10.com/static%2F2022homePage%2Fxyz10-08.png"
          alt=""
        />
        <span>能</span>
      </div>
    </transition>

    <a-carousel
      class="contain"
      :dots="false"
      :beforeChange="change"
      ref="carousel"
    >
      <!-- autoplay -->
      <!-- 数据智能 -->
      <div class="item">
        <video
          id="banner-video"
          muted="muted"
          loop
          autoplay
          poster="https://static.xyz10.com/static%2F2022homePage%2FnewData.png"
        >
          <source
            src="https://static.xyz10.com/static%2F2022homePage%2Fvideo%2FnewData.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <!-- 人工智能 -->
      <div class="item">
        <video
          id="banner-video"
          muted="muted"
          loop
          autoplay
          poster="https://static.xyz10.com/static%2F2022homePage%2FnewAI.png"
        >
          <source
            src="https://static.xyz10.com/static%2F2022homePage%2Fvideo%2FnewAI.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <!-- 时空智能 -->
      <div class="item">
        <video
          id="banner-video"
          muted="muted"
          loop
          autoplay
          poster="https://static.xyz10.com/static%2F2022homePage%2FnewMap.png"
        >
          <source
            src="https://static.xyz10.com/static%2F2022homePage%2Fvideo%2FnewMap.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </a-carousel>
    <div class="icon">
      <div class="time" :class="{ up: iconShow }">
        <img
          src="https://static.xyz10.com/static%2F2022homePage%2F%E6%97%A5%E5%8E%86_calendar-three%E7%9A%84%E5%89%AF%E6%9C%AC.png"
          alt=""
        />
        {{ time }}
      </div>
      <div class="city" :class="{ up1: iconShow }">
        <img
          src="https://static.xyz10.com/static%2F2022homePage%2F%E5%B7%B2%E5%AE%9A%E4%BD%8D_local%20(1)%E7%9A%84%E5%89%AF%E6%9C%AC.png"
          alt=""
        />
        {{ "Hang Zhou, CHINA" }}
      </div>
      <div class="area" :class="{ up2: iconShow }">
        <img
          src="https://static.xyz10.com/static%2F2022homePage%2F%E6%AD%A3%E5%90%91%E6%97%8B%E8%BD%AC_rotating-forward%E7%9A%84%E5%89%AF%E6%9C%AC.png"
          alt=""
        />
        {{ "9,596,961 km2" }}
      </div>
    </div>
    <div class="button-contain">
      <div
        class="button"
        :class="{ active: currentIndex === index }"
        v-for="(item, index) in arr"
        :key="index"
        @click="btnClick(index)"
      >
        <div class="time">{{ item.time }}</div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      iconShow: true,
      timer: null,
      arr: [
        {
          time: "26/01/2020",
          name: "数据智能",
        },
        {
          time: "25/12/2019",
          name: "人工智能",
        },
        {
          time: "30/10/2017",
          name: "时空智能",
        },
      ],
    };
  },
  methods: {
    change(from, to) {
      this.currentIndex = to;
    },
    btnClick(index) {
      this.$refs.carousel.goTo(index, false);
    },
    play() {
      const videoList = [...document.querySelectorAll("#banner-video")];
      videoList.map((video) => video.play());
    },
  },
  computed: {
    text() {
      let text = "";
      switch (this.currentIndex) {
        case 0:
          text = "数据";
          break;
        case 1:
          text = "人工";
          break;
        case 2:
          text = "时空";
          break;

        default:
          break;
      }
      return text;
    },
    time() {
      let time = "";
      switch (this.currentIndex) {
        case 0:
          time = "26/01/2020";
          break;
        case 1:
          time = "25/12/2019";
          break;
        case 2:
          time = "30/10/2017";
          break;

        default:
          break;
      }
      return time;
    },
  },
  watch: {
    currentIndex() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.iconShow = false;

      this.timer = setTimeout(() => {
        this.iconShow = true;
        clearTimeout(this.timer);
        this.timer = null;
      }, 100);
    },
  },
};
</script>

<style scoped lang="less">
.banner {
  transform: translateZ(100px);
  position: relative;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  .title {
    width: 15vw;
    // position: absolute;
    // top: 10vh;
    margin-left: 12.5vw;
    margin-top: 10vh;
    img {
      width: 100%;
      display: block;
    }
    // color: white;
    // padding: 52px 0 0 63px;
    //  font-family:'Arial';
    // font-weight: bold;
    //  font-size: 16px;
  }
  .background {
    position: absolute;
    right: 10vw;
    top: 20vh;
    // width: 44.2vw;
    height: 17%;
    line-height: 50%;
    font-size: 140px;
    font-family: PingFangSC-Semibold;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.1);
    img {
      position: absolute;
      right: 130px;
    }
  }
  .contain {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 70%;
    height: 70%;
    .item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .icon {
    transform: translateZ(100px);
    position: absolute;
    left: 5%;
    bottom: 5%;
    display: flex;
    width: 50%;
    font-weight: 900;
    color: white;
    font-family: "Arial";
    font-weight: bold;
    font-size: 16px;
    .time {
      flex-grow: 1;
      img {
        width: 38px;
        height: 38px;
        margin-right: 18px;
      }
    }
    // .up {
    //   animation: 1s iconframe;
    // }
    .city {
      flex-grow: 1;
      font-size: 18px;
      img {
        width: 38px;
        height: 38px;
        margin-right: 18px;
      }
    }
    // .up1 {
    //   animation: 2s addressframe;
    // }
    .area {
      flex-grow: 1;
      font-size: 17px;
      img {
        width: 38px;
        height: 38px;
        margin-right: 18px;
      }
    }
    // .up2 {
    //   animation: 3s areaframe;
    // }
  }
  .button-contain {
    transform: translateZ(100px);
    position: absolute;
    right: 0;
    bottom: 130px;
    width: 30%;
    height: 42%;
    cursor: pointer;
    .button {
      display: flex;
      align-items: center;
      transform: translateX(-35px);
      width: calc(100% + 80px);
      height: 33.33%;
      // background-color: #09131d;
      .time {
        line-height: 23px;
        font-family: PingFangSC-Medium;
        flex-grow: 0.8;
        font-size: 28px;
        height: 23px;
        color: rgb(255, 255, 255);
        font-weight: 900;
      }
      .name {
        font-family: "Arial";
        font-weight: bold;
        flex-grow: 1.3;
        line-height: 34px;
        font-size: 30px;
        height: 34px;
        color: rgba(255, 255, 255, 0.68);
        font-weight: 900;
      }
    }
    .active {
      padding-left: 48px;
      // width: calc(100% + 180px);
      transform: translateX(-70px);
      // margin-left: -60px;
      background-color: #091d2a;
      transition: 0.1s;
    }
  }
}
// @keyframes iconframe {
//   0% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
// @keyframes addressframe {
//   0% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   50% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
// @keyframes areaframe {
//   0% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   30% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   60% {
//     opacity: 0;
//     transform: translateY(50px);
//   }

//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
</style>