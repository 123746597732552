<template>
  <div class="footer" id="footer">
    <div class="QRcode">
      <img v-lazy="QRcode" alt="" />
    </div>
    <!-- <div class="content">
      <div class="left">
        <div class="tittle">关于我们</div>
        <div class="text_list">
          <div class="text">企业咨询</div>
          <div class="text">关于十域</div>
        </div>
      </div>
      <div class="right">
        <div class="tittle">核心产品</div>
        <div class="text_list">
          <div class="text">除和</div>
          <a class="text" href="https://www.dangwu.com/">当午</a>
          <a class="text" href="https://qz.1045fm.cn/live/#/">正午</a>
        </div>
      </div>
    </div> -->
    <div class="footer_logo">
      <img v-lazy="footerLogoSrc" alt="" />
    </div>
    <div class="foot_bottom">
      <div>Copyright @2017 杭州十域科技有限公司版权所有</div>
      <div>浙ICP备17056998号-1 浙公网安备 33010402004247号</div>
      <div>法律声明 ｜ 友情链接 ｜ 网站地图</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerLogoSrc:
        "https://static.xyz10.com/static/2022homePage/mobile/foot_logo.png",
      QRcode:
        "https://static.xyz10.com/static%2F2022homePage%2Fmobile%2F%E6%89%8B%E6%9C%BA%E7%89%88-26.png",
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  // height:87vh !important;
  color: #fff;
  img {
    width: 100%;
    display: flex;
  }
  .QRcode {
    // height: 28.5%;
    background-color: #fff;
  }
  .content {
    height: 20vh;
    background-color: #1d2a36;
    display: flex;
    justify-content: space-around;
    text-align: center;
    .tittle {
      margin-top: 4vh;
      font-size: 16px;
      font-weight: 900;
    }
    .text_list {
      text-align: left;
      margin-top: 2vh;
      opacity: 0.75;
      .text {
        margin-bottom: 5px;
      }
      a {
        display: block;
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .footer_logo {
    // height: 28.5%;
    // background-color: #f98;
  }
  .foot_bottom {
    -webkit-text-size-adjust: none;
    height: 12vh;
    background-color: #000;
    text-align: center;
    padding-top: 3vh;
    font-size: 12px;

    div {
      opacity: 0.8;
      transform: scale(0.83);
      &:last-child {
        margin-top: 1vh;
      }
    }
  }
}
</style>
