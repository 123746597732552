import { render, staticRenderFns } from "./introduce.vue?vue&type=template&id=36947e0f&scoped=true&"
import script from "./introduce.vue?vue&type=script&lang=js&"
export * from "./introduce.vue?vue&type=script&lang=js&"
import style0 from "./introduce.vue?vue&type=style&index=0&id=36947e0f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36947e0f",
  null
  
)

export default component.exports