<template>
  <div id="homepage">
    <full-page :options="options" id="fullpage" ref="fullpage">
      <div class="section first fp-auto-height">
        <first-section></first-section>
      </div>
      <div class="section second fp-auto-height">
        <second-section></second-section>
      </div>
      <div class="section third">
        <third-section></third-section>
      </div>
      <div class="section forth">
        <fourth-section></fourth-section>
      </div>
      <div class="section fifth fp-auto-height">
        <fifth-section></fifth-section>
      </div>
      <div class="section sixth fp-auto-height">
        <sixth-section></sixth-section>
      </div>
    </full-page>
  </div>
</template>

<script>
import firstSection from "../components/home.vue";
import secondSection from "../components/introduce.vue";
import thirdSection from "../components/banner.vue";
import fourthSection from "../components/product.vue";
import fifthSection from "../components/partner.vue";
// import sixthSection from "../components/footer.vue";
import sixthSection from "../components/newFooter";
export default {
  name: "homePage",
  components: {
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection,
    sixthSection,
  },
  data() {
    return {
      options: {
        autoScrolling: true,
        fitToSection: true,
        scrollOverflowReset: true,
        anchors: [
          "home",
          "introduce",
          "Carousel",
          "product",
          "partner",
          "footer",
        ],
        scrollOverflow: true,
        verticalCentered: false,
        bigSectionsDestination: "top",
      },
    };
  },
};
</script>

<style lang="less" scoped>
#homepage {
  // .fp-completely{
  //   transform: translate3d(0, 0, 0);
  //   webkit-overflow-scrolling:touch;
  // }
  .first {
    width: 100%;
  }
  .second {
    width: 100%;
  }
  .third {
    width: 100%;
    background-color: #000;
  }
  .forth {
    width: 100%;
    background-color: #000;
  }
  .fifth {
    width: 100%;
  }
  .sixth {
    width: 100%;
  }
}
</style>
