<template>
  <div class="intelligent">
    <!-- <div class="intelligent" :style="{backgroundImage:'url(' + info.url + ')', backgroundRepeat:'no-repeat', backgroundPosition:'center center',backgroundSize:'70vh'}"> -->
    <video
      preload
      loop
      muted
      autoplay
      x5-video-player-fullscreen="true"
      x5-playsinline
      playsinline
      webkit-playsinline
      :poster="info.url"
      :id="info.times"
    >
      <source :src="info.videoSrc" type="video/mp4" />
    </video>
    <div class="title">{{ info.title }}</div>
    <div class="line"></div>
    <div class="content">
      <div class="date">
        <img
          src="https://static.xyz10.com/static/2022homePage/calendar.png"
          alt=""
        />
        <div>{{ info.time }}</div>
      </div>
      <div class="date">
        <img
          src="https://static.xyz10.com/static/2022homePage/local.png"
          alt=""
        />
        <div>Hang Zhou, CHINA</div>
      </div>
      <div class="date">
        <img
          src="https://static.xyz10.com/static/2022homePage/repositioning.png"
          alt=""
        />
        <div>9,596,961 km2</div>
      </div>
    </div>
    <div class="nextIcon">
      <img
        src="https://static.xyz10.com/static%2F2022homePage%2F%E5%8F%8C%E4%B8%8B_double-down.png"
        alt=""
      />
    </div>
    <div class="logo">
      <img
        src="https://static.xyz10.com/static%2F2022homePage%2F%E9%AD%94%E6%96%B9_cube-three%E7%9A%84%E5%89%AF%E6%9C%AC2-01.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "intelligent",
  props: ["info"],
  data() {
    return {};
  },
  mounted() {
    let myvideo = document.querySelector(`#${this.info.times}`);
    // myvideo.play();
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        myvideo.play();
      },
      false
    );
    document.addEventListener(
      "YixinJSBridgeReady",
      function () {
        myvideo.play();
      },
      false
    );
  },
};
</script>

<style lang="less" scoped>
.intelligent {
  position: relative;
  width: 100vw;
  // height: 100vh;
  video {
    width: 100%;
    display: block;
  }
  .title {
    position: absolute;
    right: 27vw;
    top: 40vh;
    font-size: 22px;
    color: white;
    font-weight: 900;
  }
  .line {
    position: absolute;
    width: 52vw;
    height: 1px;
    background-color: white;
    right: 0;
    top: 45vh;
  }
  .content {
    width: 52vw;
    height: 25vh;
    // background-color: #000;
    position: absolute;
    top: 46vh;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .date {
      display: flex;
      color: #fff;
      div {
        // padding-top: 1vh;
        line-height: 7vw;
        margin-left: 4vw;
        font-weight: 900;
      }
    }
    img {
      width: 7vw;
      height: 7vw;
    }
  }
  .nextIcon {
    position: absolute;
    bottom: 1vh;
    left: 44vw;
    width: 12vw;
    height: 12vw;
    // background-color: #8aa;
    img {
      width: 100%;
    }
    animation: goDown 3s infinite;
  }
  .logo {
    position: absolute;
    bottom: 6vw;
    right: 8vw;
    width: 8vw;
    // height: 8vw;
    img {
      width: 100%;
      display: block;
    }
  }
}
@keyframes goDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  65% {
    opacity: 0.3;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
