<template>
  <div class="partner" id="partner">
    <img
      src="https://static.xyz10.com/static%2F2022homePage%2Fmobile%2F%E6%89%8B%E6%9C%BA%E7%89%88-%E6%94%B9%E7%89%88-30.png"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.partner {
  height: 100%;
  img {
    width: 100%;
  }
}
</style>