<template>
  <div id="app">
    <home-page v-if="isPc"></home-page>
    <mobile-page v-else></mobile-page>
  </div>
</template>

<script>
import homePage from "./homePage.vue";
import mobilePage from "./mobilePage.vue";

export default {
  name: "App",
  components: {
    homePage,
    mobilePage
  },
  data(){
    return{
      isPc:true
    }
  },
  mounted(){
    this.isPc = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }
};
</script>

<style lang="less">
#app {
}
</style>
