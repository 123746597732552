<template>
  <div id="mobilePage">
    <!-- <full-page :options="options" id="fullpage" ref="fullpage"> -->
    <div class="section first fp-auto-height">
      <home></home>
    </div>
    <div class="section second fp-auto-height">
      <introduce></introduce>
    </div>
    <div class="section third">
      <banner></banner>
    </div>
    <div class="section forth">
      <product></product>
    </div>
    <div class="section fifth">
      <partner></partner>
    </div>
    <div class="section sixth fp-auto-height">
      <footers></footers>
    </div>
    <!-- </full-page> -->
  </div>
</template>

<script>
import home from "../components/mobile/home.vue";
import introduce from "../components/mobile/introduce.vue";
import footers from "../components/mobile/footer.vue";
import product from "../components/mobile/product.vue";
import partner from "../components/mobile/partner.vue";
import banner from "../components/mobile/banner.vue";
export default {
  name: "mobilePage",
  components: {
    home,
    introduce,
    footers,
    product,
    partner,
    banner,
  },
  data() {
    return {
      // options: {
      //   autoScrolling: true,
      //   fitToSection: true,
      //   scrollOverflowReset: true,
      //   scrollOverflow: true,
      //   verticalCentered: false,
      //   bigSectionsDestination: "top",
      //   anchors: [
      //     "home",
      //     "introduce",
      //     "banner",
      //     "product",
      //     "partner",
      //     "footer",
      //   ],
      // },
    };
  },
};
</script>

<style lang="less" scoped>
#mobilePage {
  .first {
    width: 100%;
    background-color: #000;
  }
  .second {
    width: 100%;
    background-color: #000;
  }
  .third {
    width: 100%;
    background-color: #000;
  }
  .forth {
    width: 100%;
    background-color: #000;
  }
  .fifth {
    width: 100%;
    background-color: #000;
  }
  .sixth {
    width: 100%;
    background-color: #000;
  }
}
</style>
