<template>
  <div class="banner" id="Carousel">
    <intelligent :info="info1"></intelligent>
    <intelligent :info="info2"></intelligent>
    <intelligent :info="info3"></intelligent>
  </div>
</template>

<script>
import intelligent from "../mobile/intelligent/intelligent.vue";
export default {
  name: "banner",
  components: {
    intelligent,
  },
  data() {
    return {
      info1: {
        times: "one",
        title: "数据智能",
        time: "25/12/2019",
        url: "https://static.xyz10.com/static%2F2022homePage%2Fmobile%2Fm_newData.png", //视频加载完成前显示图片
        videoSrc:
          "https://static.xyz10.com/static%2F2022homePage%2Fvideo%2Fm_newData.mp4",
      },
      info2: {
        times: "two",
        title: "人工智能",
        time: "25/12/2019",
        url: "https://static.xyz10.com/static%2F2022homePage%2Fmobile%2Fm_newAI.png",
        videoSrc:
          "https://static.xyz10.com/static%2F2022homePage%2Fvideo%2Fm_newAI.mp4",
      },
      info3: {
        times: "three",
        title: "时空智能",
        time: "25/12/2019",
        url: "https://static.xyz10.com/static%2F2022homePage%2Fmobile%2Fm_newMap.png",
        videoSrc:
          "https://static.xyz10.com/static%2F2022homePage%2Fvideo%2Fm_newMap.mp4",
      },
    };
  },
};
</script>

<style lang="less" scoped></style>
