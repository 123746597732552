<template>
  <div class="partner">
    <img
      style="width: 100%"
      src="https://static.xyz10.com/static%2F2022homePage%2F%E7%89%88-7.png"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.partner {
  transform:translateZ(100px); 
  height: 100%;
  img {
    display: block;
  }
}
</style>