<template>
  <div class="header">
    <video
      @mouseover="play"
      ref="video"
      class="video"
      loop
      muted
      autoplay
      poster="https://static.xyz10.com/static/2022homePage/PChomeVideo.png"
    >
      <source
        src="https://static.xyz10.com/static/2022homePage/video/PChomeVideo.mp4"
        type="video/mp4"
      />
    </video>
    <div class="logo"></div>
    <ul class="banner">
      <div class="banner_select">
        <!-- <li><a href="#">首页</a></li> -->
        <li><a href="#introduce">企业介绍</a></li>
        <li><a href="#Carousel">技术能力</a></li>
        <li><a href="#product">核心产品</a></li>
        <li><a href="#partner">合作伙伴</a></li>
      </div>
    </ul>
    <div class="xyz_name">
      <img
        src="https://static.xyz10.com/static/2022homePage/%E5%85%AC%E5%8F%B8name.png"
        alt=""
      />
    </div>
    <div class="next">
      <img
        src="https://static.xyz10.com/static%2F2022homePage%2F%E5%8F%8C%E4%B8%8B_double-down.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    play() {
      this.$refs.video.play();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  transform: translateZ(100px);
  // height:100%;
  position: relative;
  .video {
    width: 100%;
    display: block;
  }
  .logo {
    width: 45px;
    height: 53px;
    background: url("https://static.xyz10.com/static%2F2022homePage%2F%E9%AD%94%E6%96%B9_cube-three%E7%9A%84%E5%89%AF%E6%9C%AC2-01.png");
    background-size: 100%;
    position: absolute;
    top: 80px;
    left: 80px;
  }
  .banner:hover .banner_select {
    cursor: pointer;
    height: 220px;
  }
  .banner {
    width: 40px;
    height: 40px;
    background: url("https://static.xyz10.com/static/2022homePage/%E5%88%97%E8%A1%A82_list-two%E7%9A%84%E5%89%AF%E6%9C%AC.png");
    background-size: 100%;
    position: absolute;
    top: 80px;
    right: 80px;
  }
  .banner_select {
    height: 0;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0px;
    padding-top: 47px;
    right: 5px;
    transition: all 0.5s;
    li {
      user-select: none;
      width: 62px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      line-height: 25px;
      text-align: right;
      cursor: pointer;
      a {
        border-radius: 5px;
        font-family: PingFangSC-Medium;
        padding: 0 2px;
        text-decoration: none;
        color: #fff;
        background-color: rgba(6, 32, 44, 1);
      }
    }
    li:hover a {
      // color: aqua;
      background-color: rgba(000, 000, 000, 0);
    }
  }
  .xyz_name {
    width: 30vw;
    height: 15vw;
    position: absolute;
    top: 28vh;
    left: 6vw;
    img {
      width: 100%;
      display: block;
    }
  }
  .next {
    position: absolute;
    bottom: 10vh;
    right: 48vw;
    width: 5vw;
    height: 3vw;
    img {
      width: 100%;
      display: block;
    }
    animation: goDown 3s infinite;
  }
}

@keyframes goDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  65% {
    opacity: 0.3;
    transform: translateY(60%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>