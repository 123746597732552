<template>
  <div class="product">
    <div
      class="img_list dangwu"
      @mouseover="() => (cricle2ByHover = true)"
      @mouseleave="() => (cricle2ByHover = false)"
      @click="openDangwu"
    >
      <div class="title">当午</div>
      <div class="desc">全域时空智能服务</div>
      <img
        class="logo"
        src="https://static.xyz10.com/static/2022homePage/2%282%29%E7%9A%84%E5%89%AF%E6%9C%AC2.png"
        alt=""
      />
      <img
        :class="{ icon: true, iconHover: cricle2ByHover }"
        src="https://static.xyz10.com/static/2022homePage/circle.png"
        alt=""
      />
    </div>
    <div
      class="img_list"
      @mouseover="() => (cricleByHover = true)"
      @mouseleave="() => (cricleByHover = false)"
      @click="openChuhe"
    >
      <div class="title">除和</div>
      <div class="desc">实时数仓OLAP引擎</div>
      <img
        class="logo"
        src="https://static.xyz10.com/static/2022homePage/%E9%99%A4%E5%92%8Clogo.png"
        alt=""
      />
      <img
        :class="{ icon: true, iconHover: cricleByHover }"
        src="https://static.xyz10.com/static/2022homePage/circle.png"
        alt=""
      />
    </div>

    <div
      class="img_list zhengwu"
      @mouseover="() => (cricle3ByHover = true)"
      @mouseleave="() => (cricle3ByHover = false)"
      @click="openZhengwu"
    >
      <div class="title">正午</div>
      <div class="desc">云上智慧政务大厅</div>
      <img
        class="logo"
        src="https://static.xyz10.com/static/2022homePage/%E6%AD%A3%E5%8D%88logo.png"
        alt=""
      />
      <!-- <div class="expect">敬请期待</div> -->
      <img
        :class="{ icon: true, iconHover: cricle3ByHover }"
        src="https://static.xyz10.com/static/2022homePage/circle.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cricleByHover: false,
      cricle2ByHover: false,
      cricle3ByHover: false,
    };
  },
  methods: {
    openChuhe() {
      window.open("https://chuhe.xyz10.com/");
    },
    openDangwu() {
      window.open("https://www.dangwu.com/");
    },
    openZhengwu() {
      // window.open("https://qz.1045fm.cn/live/#/");
      this.$router.push("/zhengwu");
    },
  },
};
</script>

<style lang="less" scoped>
.product {
  transform: translateZ(100px);
  display: flex;
  height: 100%;
  .img_list {
    position: relative;
    width: 33.33%;
    height: 100%;
    cursor: pointer;
    background: url("https://static.xyz10.com/static/2022homePage/%E9%99%A4%E5%92%8C%E8%83%8C%E6%99%AF.png")
      no-repeat center 10%;
    .title {
      font-family: PingFangSC-Semibold;
      position: absolute;
      left: 50%;
      top: 145px;
      font-size: 68px;
      color: white;
      transform: translateX(-50%);
    }
    .desc {
      font-family: PingFangSC-Semibold;
      position: absolute;
      left: 50%;
      top: 265px;
      font-size: 19px;
      min-width: 230px;
      text-align: center;
      color: white;
      transform: translateX(-50%);
    }
    .logo {
      position: absolute;
      left: 50%;
      top: 295px;
      width: 228px;
      // height: 210px;
      transform: translateX(-50%);
    }
    .icon {
      position: absolute;
      left: 50%;
      bottom: 85px;
      width: 28px;
      height: 28px;
      transform: translateX(-50%);
    }
    .iconHover {
      animation: circleFrame 4s infinite;
    }
  }
  .dangwu {
    background: url("https://static.xyz10.com/static/2022homePage/%E5%BD%93%E5%8D%88%E7%9A%84%E5%89%AF%E6%9C%AC2.png")
      no-repeat center;
  }
  .zhengwu {
    background: url("https://static.xyz10.com/static/2022homePage/%E6%AD%A3%E5%8D%88%E7%9A%84%E5%89%AF%E6%9C%AC2.png")
      no-repeat center;
  }
}

@keyframes circleFrame {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }

  50% {
    opacity: 0.6;
    transform: translateX(-50%) scale(1.5);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}
</style>
