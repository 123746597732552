<template>
  <div class="introduce" id="introduce">
    <!-- <img v-lazy="imgSrc" alt="" /> -->
    <img :src="imgSrc"  alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgSrc:
        "https://static.xyz10.com/static%2F2022homePage%2Fmobile%2F%E6%89%8B%E6%9C%BA%E7%89%88-29.png",
    };
  },
};
</script>
<style lang="less" scoped>
.introduce {
  // height: auto !important;
  min-height: 60vh;
  img {
    width: 100%;
  }
}
</style>
