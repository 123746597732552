<template>
  <div class="home">
    <video
      ref="video"
      class="video"
      preload
      loop
      muted
      autoplay
      x5-video-player-fullscreen="true"
      x5-playsinline
      playsinline
      webkit-playsinline
      poster="https://static.xyz10.com/static/2022homePage/mobile/%E5%85%A8%E6%96%B0%E5%85%A8%E6%96%B00.png"
    >
      <source
        src="https://static.xyz10.com/static/2022homePage/video/%E5%85%A8%E6%96%B0%E5%85%A8%E6%96%B0_pro.mp4"
        type="video/mp4"
      />
    </video>
    <div class="logo"></div>
    <ul class="banner">
      <div class="banner_select">
        <!-- <li><a href="#">首页</a></li> -->
        <li><a href="#introduce">企业介绍</a></li>
        <li><a href="#banner">技术能力</a></li>
        <li><a href="#product">核心产品</a></li>
        <li><a href="#partner">合作伙伴</a></li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  mounted() {
    let video = document.querySelector("video");
    // video.play();
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        video.play();
      },
      false
    );
    document.addEventListener(
      "YixinJSBridgeReady",
      function () {
        video.play();
      },
      false
    );
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// .home{
//     width: 100%;
//     .video{
//         height: 100%;
//     }
// }
.home {
  min-height: 90vh;
  // height:100%;
  position: relative;
  font-family: ZauriSans;
  .video {
    // height: 100%;
    width: 100%;
    display: block;
  }
  .logo {
    width: 32px;
    height: 40px;
    background: url("https://static.xyz10.com/static%2F2022homePage%2F%E9%AD%94%E6%96%B9_cube-three%E7%9A%84%E5%89%AF%E6%9C%AC2-01.png")
      no-repeat;
    background-size: 100%;
    position: absolute;
    top: 31px;
    left: 38px;
  }
  .banner:hover .banner_select {
    height: 220px;
  }
  .banner {
    width: 32px;
    height: 30px;
    background: url("https://static.xyz10.com/static/2022homePage/%E5%88%97%E8%A1%A82_list-two%E7%9A%84%E5%89%AF%E6%9C%AC.png");
    background-size: 100%;
    position: absolute;
    top: 37px;
    right: 34px;
  }
  .banner_select {
    height: 0;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0px;
    padding-top: 55px;
    right: 4px;
    transition: all 0.5s;
    li {
      user-select: none;
      width: 62px;
      height: 30px;
      color: #fff;
      font-size: 13px;
      line-height: 25px;
      text-align: right;
      a {
        text-decoration: none;
        color: #fff;
        background-color: rgba(6, 32, 44, 0.4);
      }
    }
    li:hover a {
      background-color: rgba(000, 000, 000, 0);
    }
  }
}
</style>
