<template>
  <div class="introduce">
    <div class="logo">
      <img
        src="https://static.xyz10.com/static/2022homePage/biglogo.png"
        alt=""
      />
    </div>
    <div class="introduce_list">
      <div class="list">
        <div class="list_tittle">企业资质</div>
        <div class="list_tittle_en"><span>E</span>NTERPRISE QUALIFICATION</div>
        <div class="slogan">
          <img
            src="https://static.xyz10.com/static%2F2022homePage%2F%E5%9C%86%E5%9C%88_circles-seven%E7%9A%84%E5%89%AF%E6%9C%AC.png"
            alt=""
          />
          <span> 国家高新技术领军者</span>
        </div>
        <div class="list_content">
          <p>
            公司成立于2017年，是国家高新技术企业、浙江省领军型团队、浙江省千人计划领衔企业；
          </p>
          <p>
            在海量实时数据计算技术方面，已经申请和获得授权17项专利、25项软著，与国内外知名学术研究机构联合发表国际顶级会议论文20余篇。
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list_tittle">核心产品</div>
        <div class="list_tittle_en"><span>C</span>ORE PRODUCTS</div>
        <div class="slogan">
          <img
            src="https://static.xyz10.com/static%2F2022homePage%2F%E9%9C%87%E8%8D%A1%E5%99%A8_oscillator%E7%9A%84%E5%89%AF%E6%9C%AC.png"
            alt=""
          />
          <span>“去<span class="small-caps">Hadoop</span>化”OLAP引擎</span>
        </div>
        <div class="list_content">
          <p>
            “除和-云原生实时OLAP引擎”是一款基于自主研发的国产化OLAP数据库技术打造的实时大数据计算平台；
          </p>
          <p>
            是业界第一个“去<span class="small-caps">Hadoop</span
            >化”大数据计算引擎，具备云原生、存算分离、组件化部署、完整兼容生态等特性；高效读写，达到秒级实时计算。
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list_tittle">团队基因</div>
        <div class="list_tittle_en"><span>T</span>EAM GANE</div>
        <div class="slogan">
          <img
            src="https://static.xyz10.com/static%2F2022homePage%2F%E4%BA%BA%E7%BE%A4_peoples-two%E7%9A%84%E5%89%AF%E6%9C%AC.png"
            alt=""
          />
          <span> 名校大厂的精英团队</span>
        </div>
        <div class="list_content">
          <p>
            创始团队成员毕业于浙大、美国密歇根大学等国内外知名院校，曾任职于NEC、微软、国家应急管理部、阿里、新浪等等全球知名科研机构和互联网巨头企业；
          </p>
          <p>
            团队深耕数据智能、人工智能、时空智能领域数年，成员具备计算机技术、大数据产品技术、机器学习的背景和丰富的实战经验。
          </p>
        </div>
      </div>
      <div class="list">
        <div class="list_tittle">业务情况</div>
        <div class="list_tittle_en"><span>B</span>USINESS SITUATION</div>
        <div class="slogan">
          <img
            src="https://static.xyz10.com/static%2F2022homePage%2FAPI%20%E5%BA%94%E7%94%A8_api-app%E7%9A%84%E5%89%AF%E6%9C%AC.png"
            alt=""
          />
          <span>能源 政务 化工 交通 商业</span>
        </div>
        <div class="list_content">
          <p>
            十域科技已成为能源电力领域核心数据服务提供商、浙江省委省政府数字化改革核心技术供应商、亚运会官方合作服务商之一；
          </p>
          <p>
            助力电力、化工、交通、政务等行业客户实现了数据分析场景中部分关键系统的国产替代，累计落地13套国网和25套省网业务系统，日均处理25TB新增数据。
          </p>
        </div>
      </div>
    </div>
    <div class="smart_xyz">
      <img
        src="https://static.xyz10.com/static%2F2022homePage%2F%E5%90%8D%E5%AD%97-06.png"
        alt=""
      />
    </div>
  </div>
  <!-- <div class="introduce">
    <img src="https://static.xyz10.com/static%2F2022homePage%2F%E7%BB%882-2-05.png" alt="">
  </div> -->
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.introduce {
  transform: translateZ(100px);
  overflow: hidden;
  height: 720px;
  background-color: #000;
  position: relative;
  width: 100%;
  .small-caps {
    font-family: small-caps !important;
  }
  img {
    width: 100%;
    display: block;
  }
  .logo {
    width: 33vw;
    max-width: 530px;
    height: 65vh;
    max-height: 600px;
    position: absolute;
    bottom: 3vw;
    left: 0;
    // img{
    //   width: 100%;
    // }
    // background: url("https://static.xyz10.com/static/2022homePage/biglogo.png")
    //   no-repeat;
    // background-size: 100%;
  }
  .introduce_list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    .list {
      width: 17.4vw;
      // margin-right: 3vw;
      height: 28vh;

      &:last-child {
        margin-right: 0;
      }
      .list_tittle {
        font-family: PingFangSC-Regular;
        text-align: center;
        height: 32px;
        font-size: 28px;
        // font-weight: 900;
        color: #fff;
      }
      .list_tittle_en {
        margin: 0;
        padding: 0;
        font-size: 12px;
        margin-top: 19px;
        font-family: small-caps;
        color: #fff;
        text-align: center;
        letter-spacing: 2px;
        span {
          display: inline-block;
          font-size: 16px;
        }
      }
      .slogan {
        margin-top: 15px;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #fff;
        height: 35px;
        line-height: 35px;
        img {
          display: inline-block;
          width: 22px;
          height: 22px;
          vertical-align: middle;
          margin-right: 6px;
          margin-bottom: 2px;
        }
      }
      .list_content {
        font-family: PingFangSC-Regular;
        letter-spacing: 1.2px;
        margin-top: 18px;
        font-size: 14px;
        color: #fff;
        opacity: 0.7;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 10; //当属性值为3，表示超出3行隐藏
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  // .arrow {
  //   position: absolute;
  //   right: 12.5vw;
  //   top: 80px;
  //   height: 9.5px;
  //   width: 7vw;
  //   img {
  //     width: 100%;
  //   }
  // }
  .smart_xyz {
    position: absolute;
    right: 12.5vw;
    top: 95px;
    width: 15vw;
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
