<template>
  <div
    class="card"
    @click="goUrl"
    :style="{
      backgroundImage: 'url(' + arr.back + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: '70vh',
    }"
  >
    <div class="name">{{ arr.name }}</div>
    <div class="desc">{{ arr.des }}</div>
    <img class="icon" :src="arr.icon" alt="" />
    <img
      class="go"
      src="https://static.xyz10.com/static%2F2022homePage%2Fmobile%2F%E5%8F%B3-%E7%AE%AD%E5%A4%B4_arrow-circle-right%E7%9A%84%E5%89%AF%E6%9C%AC.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "card",
  props: ["arr"],
  data() {
    return {};
  },
  methods: {
    goUrl() {
      if(this.arr.url){
        window.open(this.arr.url);
      }else{
        this.$router.push(this.arr.routes)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  position: relative;
  width: 90vw;
  height: 30vh;
  color: white;
  margin-left: 5vw;
  margin-bottom: 2vh;
  border-radius: 6vw;
  font-family: ZauriSans;
  font-weight: 900;
  .name {
    position: absolute;
    top: 6vh;
    left: 10vw;
    font-size: 7vw;
  }
  .desc {
    position: absolute;
    top: 12vh;
    left: 10vw;
    font-size: 2vw;
  }
  .icon {
    position: absolute;
    right: 0vw;
    top: 3vh;
    width: 30vw;
    // height: 28vw;
  }
  .go {
    position: absolute;
    left: 10vw;
    top: 18vh;
    width: 8vw;
    // height: 10vw;
  }
}
</style>